/*======================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
======================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.error-form {
  color: red;
  font-size: 13px;
  margin-top: 7px;
}
.custom-file-upload {
  color: #fff;
  background: #7367f0;
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
}
.link-hook {
  font-size: 14px;
  margin-top: 20px;
}
.hook {
  width: 100%;
  background: #f0f0f1;
  border-radius: 10px;
  padding: 10px;

  .hook-copy {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 17px;
    }
  }
}
.spinner-load {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.card-img {
  height: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .product-img {
    height: 100%;
  }
  .notfound-img {
    height: 100px;
    margin: 4.5rem 0;
  }
}
.cost_input::placeholder,
.selling_price::placeholder {
  text-align: right;
}
.apexcharts-legend-marker{
  background: red !important;
  position: relative;
  width: 20px !important;
  height: 20px !important;
  &::after{
    content: "";
    width: 70%;
    height: 70%;
    background: white;
    position: absolute;
    top: 50%;  /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
}
.apexcharts-legend, .apexcharts-align-center, .apx-legend-position-bottom{
  display: none !important;
}
.label-chart{
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 7px;
  margin-bottom: 10px;
  &~div{
    align-self: stretch;
    margin-top: 2px;
  }
}
.details-chart{
  flex: 1;
 & > p{
   margin: 0;
   padding: 0;
  font-size: 12px;
 }
 & > span{
   text-align: center;
   font-weight: bold;
   font-size: 18px;
 }
}
.chart1{
  background-color: transparent;
  border : 3px solid #7367F0;
}
.chart2{
  background-color: transparent;
  border : 3px solid #55D38D;
}
.chart3{
  background-color: transparent;
  border : 3px solid #EA5455;
}
.statistics-body{
  box-sizing: border-box;
}
.chart-container{
  height: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}
.chart-container > canvas {
  height: 200px !important;
  width: 200px !important;
}

@media screen and (max-width: 1500px){
  .chart-container{
     margin-bottom: 3.2rem;
  }
 
 }
 @media screen and (min-width: 1500px){
  .chart-container{
     margin-bottom: 2.1rem;
  }
 
 }